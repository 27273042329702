// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconReportMoney, IconUserCheck, IconDeviceLaptop, IconCompass } from '@tabler/icons-react';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconReportMoney,
    IconUserCheck,
    IconDeviceLaptop,
    IconCompass
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDeviceLaptop,
            breadcrumbs: false
        },
        {
            id: 'machines',
            title: 'Machines',
            type: 'item',
            url: '/machines',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'venues',
            title: 'Venues',
            type: 'item',
            url: '/venues',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'analytics',
            title: 'Analytics',
            type: 'item',
            url: '/analytics',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'manufacturer',
            title: 'Manufacturer',
            type: 'item',
            url: '/manufacturer',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'developer',
            title: 'Developer',
            type: 'item',
            url: '/developer',
            icon: icons.IconCompass,
            breadcrumbs: false
        },
        {
            id: 'account',
            title: 'Account',
            type: 'item',
            url: '/account',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        }
        // {
        //     id: 'default',
        //     title: <FormattedMessage id="default" />,
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'analytics',
        //     title: <FormattedMessage id="analytics" />,
        //     type: 'item',
        //     url: '/dashboard/analytics',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // }
    ]
};

export default dashboard;
