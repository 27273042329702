// action - state management
import { LOADER } from './actions';

// ==============================|| LOADER REDUCER ||============================== //
const initialState = {
    showLoader: false
};

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADER: {
            const { showLoader } = action;
            return {
                ...state,
                showLoader
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default loaderReducer;
