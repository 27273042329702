import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';
import DefaultUserImage from 'assets/images-scorbit/default-user.png';

// assets
import { IconLogout, IconSettings, IconHelp, IconLink, IconCaretDown } from '@tabler/icons-react';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { logout, user } = useAuth();
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Avatar
                            src={user?.profile_picture || DefaultUserImage}
                            sx={{
                                ...theme.typography.mediumAvatar,
                                margin: '8px 0 8px 8px !important',
                                cursor: 'pointer'
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            color="inherit"
                        />
                        <span style={{ marginLeft: '5px', marginRight: '2px', fontSize: '18px' }}>
                            {user?.first_name && user?.last_name && `${user?.first_name} ${user?.last_name}`}
                            {!(user?.first_name && user?.last_name) && (user?.display_name ? user?.display_name : user?.username)}
                        </span>
                    </div>
                }
                label={<IconCaretDown stroke={1.5} size="1.5rem" fill={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [100, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack sx={{ width: '100%' }}>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4" sx={{ fontWeight: 400 }}>
                                                    Signed in as
                                                </Typography>
                                                <Typography component="span" variant="h4">
                                                    {user?.username}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Divider />
                                    <List
                                        component="nav"
                                        sx={{
                                            width: '100%',
                                            maxWidth: 350,
                                            minWidth: 300,
                                            backgroundColor: theme.palette.background.paper,
                                            borderRadius: '10px',
                                            [theme.breakpoints.down('md')]: {
                                                minWidth: '100%'
                                            },
                                            '& .MuiListItemButton-root': {
                                                mt: 0.5
                                            }
                                        }}
                                    >
                                        <ListItemButton
                                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                                            selected={selectedIndex === 0}
                                            onClick={() => {
                                                window.location.href = 'https://www.scorbit.io/my-account';
                                            }}
                                        >
                                            <ListItemIcon>
                                                <IconSettings stroke={1.5} size="1.3rem" />
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                                            selected={selectedIndex === 0}
                                            onClick={() => {
                                                window.location.href = 'https://support.scorbit.io/hc/en-us';
                                            }}
                                        >
                                            <ListItemIcon>
                                                <IconHelp stroke={1.5} size="1.3rem" />
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography variant="body2">Help</Typography>} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                                            selected={selectedIndex === 0}
                                            onClick={() => {
                                                window.location.href = 'https://www.scorbit.io';
                                            }}
                                        >
                                            <ListItemIcon>
                                                <IconLink stroke={1.5} size="1.3rem" />
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography variant="body2">Scorbit.io</Typography>} />
                                        </ListItemButton>
                                        <ListItemButton
                                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                                            selected={selectedIndex === 4}
                                            onClick={handleLogout}
                                        >
                                            <ListItemIcon>
                                                <IconLogout stroke={1.5} size="1.3rem" />
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography variant="body2">Sign out</Typography>} />
                                        </ListItemButton>
                                    </List>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
