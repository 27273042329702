import PropTypes from 'prop-types';

// material-ui
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import useAuth from 'hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const auth = useAuth();
    let isMfr = false;
    if (auth?.user) {
        if (auth.user.groups.some((g) => g.name.substr(0, 4) === 'mfr/' || g.name.substr(0, 4) === 'adv/' || g.name === 'admin')) {
            isMfr = true;
        }
    }

    // menu list collapse & items
    const items = item.children
        ?.filter((item) => {
            if (item.id === 'manufacturer' && !isMfr) {
                return false;
            }
            return true;
        })
        .map((menu) => {
            switch (menu.type) {
                case 'collapse':
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                case 'item':
                    return <NavItem key={menu.id} item={menu} level={1} />;
                default:
                    return (
                        <Typography key={menu.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return (
        <>
            <List>{items}</List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
