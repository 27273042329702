import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'axios';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const getCookie = (cName) => {
    const name = `${cName}=`;
    const cDecoded = decodeURIComponent(window.document.cookie); // to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach((val) => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
};

const setCookie = (serviceToken) => {
    const expireDate = new Date();
    expireDate.setMonth(expireDate.getMonth() + 1);

    if (window.location.host.endsWith('scorbit.io')) {
        window.document.cookie = `token=${serviceToken};domain=.scorbit.io;expires=${expireDate.toUTCString()}`;
    } else if (window.location.host.endsWith('pages.dev')) {
        window.document.cookie = `token=${serviceToken};domain=.scorbit-opcenter.pages.dev;expires=${expireDate.toUTCString()}`;
    } else {
        window.document.cookie = `token=${serviceToken};expires=${expireDate.toUTCString()}`;
    }
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        setCookie(serviceToken);
        axios.defaults.headers.common.Authorization = `Token ${serviceToken}`;
    } else {
        setCookie('');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = getCookie('token');
                if (serviceToken) {
                    setSession(serviceToken);
                    const user = await axios.get(`${process.env.REACT_APP_SCORBIT_TOOLS_API_URL}/api/user_profile/get/`);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: user.data
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        setSession(null);

        const response = await axios.post(`${process.env.REACT_APP_SCORBIT_TOOLS_API_URL}/api/auth/`, {
            username: email,
            password
        });

        const { token } = response.data;

        setSession(token);

        const user = await axios.get(`${process.env.REACT_APP_SCORBIT_TOOLS_API_URL}/api/user_profile/get/`);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: user.data
            }
        });
    };

    const register = async (email, password, firstName, lastName, username, initials, displayName) => {
        const response = await axios.post(`${process.env.REACT_APP_SCORBIT_TOOLS_API_URL}/api/account/register/`, {
            username,
            password,
            first_name: firstName,
            last_name: lastName,
            email,
            initials,
            display_name: displayName
        });

        if (response) {
            await login(username, password);
        }
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (email) => {
        await axios.post(`${process.env.REACT_APP_SCORBIT_TOOLS_API_URL}/api/account/send-reset-password-link/`, {
            email: email.toLowerCase()
        });
    };

    const sendVerificationEmail = async (email) => {
        await axios.post(`${process.env.REACT_APP_SCORBIT_TOOLS_API_URL}/api/account/send-reset-password-link/`, {
            email: email.toLowerCase()
        });
    };
    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile, sendVerificationEmail }}>
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
